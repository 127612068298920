import * as yup from 'yup';

const max = 5000;
const message = `Your vehicleDamageDescription may not be than ${max.toLocaleString()} characters.`;

const schema = {
  accidentDescription: yup.string().default('').max(max, message).required('This field is required'),
  additionalComments: yup.string().default('').max(max, message),
  policeNotified: yup.string().default(''),
  policeDepartment: yup.string().default(''),
  policeReportNumber: yup.string().default(''),
  reportFiled: yup.string().default(''),
};

export default schema;
export { max };