import React from 'react';
import clsx from 'clsx';
import parse from 'html-react-parser';
import { animated } from 'react-spring';
import { Container } from 'react-bootstrap';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';
import useSliderBlock from './hooks/useSliderBlock';
import Slide from './components/Slide/Slide';

const SliderBlock = ({ className, data, ...rest }) => {
  const {
    animatedHeight,
    ariaLabel,
    currentSlideHasNoImage,
    handleMove,
    handleReady,
    isPaginationVisible,
    ref,
    slides,
    updateHeight,
  } = useSliderBlock(data);

  return (
    <section
      className={clsx('SliderBlock', className, { isPaginationVisible })}
      ref={ref}
      {...rest}
    >
      <Container fluid="lg">
        <Splide
          aria-label={parse(ariaLabel)}
          hasTrack={false}
          onMove={handleMove}
          onReady={handleReady}
          onResized={updateHeight}
          options={{
            arrows: false,
            ...(!isPaginationVisible && {
              drag: false,
              pagination: false,
            })
          }}
        >
          <animated.div style={{ height: animatedHeight }}>
            <SplideTrack>
              {slides.map(({ id, ...slide }) => (
                <SplideSlide key={id}>
                  <Slide
                    className="SliderBlock__slide"
                    slide={slide}
                  />
                </SplideSlide>
              ))}
            </SplideTrack>
          </animated.div>
          {isPaginationVisible && (
            <ul className={clsx('splide__pagination', { currentSlideHasNoImage })} />
          )}
        </Splide>
      </Container>
    </section>
  );
};

export default SliderBlock;
