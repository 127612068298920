import * as yup from 'yup';
const max = 1000;
const message = `Your injury description may not be longer than ${max.toLocaleString()} characters.`;

const schema = {
  
  isAnotherVehicleInvolved: yup.string().default(''),
  otherVehicleYear: yup.string().default(''),
  otherVehicleMake: yup.string().default(''),
  otherVehicleModel: yup.string().default(''),
  otherVehicleLicensePlateNumber: yup.string().default(''),
  otherVehicleVehicleColor: yup.string().default(''),
  otherVehicleVehicleVin: yup.string().default(''),
  isOtherVehicleDamaged: yup.string().default(''),
  otherVehicleDamageDescription: yup.string().default(''),
  otherVehicleInitialPointOfImpact: yup.string().default(''),
  otherVehicleUnrelatedPriorDamage: yup.string().default(''),
  isOtherVehicleDrivable: yup.string().default(''),
  otherVehicleLocationStreetAddress: yup.string().default(''),
  otherVehicleLocationCity: yup.string().default(''),
  otherVehicleLocationState: yup.string().default(''),
  otherVehicleLocationZip: yup.string().default(''),
  isOtherVehicleBusinessAddress: yup.string().default(''),
  OtherVehicleBusinessName: yup.string().default(''),
  OtherVehicleBusinessPhone: yup.string().default(''),
  OtherVehicleInsuranceCompanyName: yup.string().default(''),
  otherVehicleInsuranceCompanyPolicyNumber: yup.string().default(''),
};

export default schema;
export { max };