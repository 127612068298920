import React, { useMemo } from 'react';
import clsx from 'clsx';
import Markdown from 'markdown-to-jsx';
import { ReactComponent as SVGlinkedin } from '../../assets/linkedin.svg';

const CompanyInfo = ({ className, companyInfo }) => {
  const { address, email, linkedinUrl } = companyInfo;
  const icons = useMemo(() => [{
    SVG: SVGlinkedin,
    url: linkedinUrl,
  }], [linkedinUrl]);

  return (
    <div className={clsx('CompanyInfo', className)}>
      <Markdown
        className="CompanyInfo__address"
        options={{ forceBlock: true }}
      >
        {address}
      </Markdown>

      <a
        className="CompanyInfo__email"
        href={`mailto:${email}`}
      >
        {email}
      </a>

      <div className="CompanyInfo__icons">
        {icons.map(({ SVG, url }) => (
          <a
            className="CompanyInfo__icon__wrapper"
            href={url}
            key={url}
            target="_blank"
            rel="noreferrer"
          >
            <SVG className="CompanyInfo__icon" />
          </a>
        ))}
      </div>
    </div>
  );
};

export default CompanyInfo;
