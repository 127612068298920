import * as yup from 'yup';

const max = 5000;
const message = `Your vehicleDamageDescription may not be than ${max.toLocaleString()} characters.`;

const schema = {
  lossDescription: yup.string().default('').max(max, message).required('This field is required'),
  policeNotified: yup.string().default('').required('This field is required'),
  policeDepartment: yup.string().default(''),
  policeReportNumber: yup.string().default(''),
  reportFiled: yup.string().default(''),
  fireDepartmentNotified: yup.string().default('').required('This field is required'),
  fireDepartment: yup.string().default(''),
  fireDepartmentReportNumber: yup.string().default(''),
  hasAdditionalWitness: yup.string().default('').required('This field is required'),
  additionalWitnessFirstName: yup.string().default(''),
  additionalWitnessLastName: yup.string().default(''),
  additionalWitnessStreetAddress: yup.string().default(''),
  additionalWitnessCity: yup.string().default(''),
  additionalWitnessState: yup.string().default(''),
  additionalWitnessZip: yup.string().default(''),
  additionalWitnessPhone: yup.string().default(''),
  additionalWitnessEmail: yup.string().default(''),
  contactEmail: yup.string().default(''),
  additionalComments: yup.string().default('').max(max, message),

};

export default schema;
export { max };