import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Container } from 'react-bootstrap';
import HeaderParagraphBlock from '../../ContentBlocks/HeaderParagraphBlock/HeaderParagraphBlock';
import Tiles from '../../../../Tiles/Tiles';
import useHeaderAs from '../../../hooks/useHeaderAs';

const TileBlock = ({ className, data, ...rest }) => {
  const [ref, headerAs] = useHeaderAs('h1', 'h2');
  const tilesHeaderAs = useMemo(() => (!data.header ? 'h2' : 'h3'), [data]);

  return (
    <section
      className={clsx('TileBlock__wrapper', className)}
      ref={ref}
      {...rest}
    >
      <Container>
        <div className="TileBlock">
          <HeaderParagraphBlock
            as="div"
            data={{
              body: data.body,
              header: data.header,
            }}
            headerAs={headerAs}
          />
          <Tiles
            className="TileBlock__tiles"
            headerAs={tilesHeaderAs}
            tiles={data.tiles}
            displayAsIcons={data.displayAsIcons}
          />
        </div>
      </Container>
    </section>
  );
};

export default TileBlock;
