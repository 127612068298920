import * as yup from 'yup';

const schema = {
  reporterFirstName: yup.string().default('').required('This field is required.'),
  // reporterMiddleName: yup.string().default(''),
  reporterLastName: yup.string().default('').required('This field is required.'),
  reporterPhone: yup.string().default('').required('This field is required.'),
  reporterEmail: yup.string().default('').email('Please enter a valid email address.').required('This field is required.'),
};

export default schema;
