import { useEffect } from 'react';

const useScreen = ({ isInvalid, screenIndex, wizard }) => (
  useEffect(() => {
    if (screenIndex !== wizard.currentScreenIndex) return;

    if (isInvalid) {
      wizard.update(false, screenIndex);
      return;
    }

    // valid!
    wizard.update(true, screenIndex);
  }, [isInvalid, screenIndex, wizard])
);

export default useScreen;
