import Screen0 from '../Screen0';
import schema0 from '../Screen0/schema';

import Screen1 from '../Screen1';
import schema1 from '../Screen1/schema';

import Screen2 from '../Screen2';
import schema2 from '../Screen2/schema';

import Screen3 from '../Screen3';
import schema3 from '../Screen3/schema';

import Screen4 from '../Screen4';
import schema4 from '../Screen4/schema';

import Screen5 from '../Screen5';
import schema5 from '../Screen5/schema';

import Screen6 from '../Screen6';
import schema6 from '../Screen6/schema';

import Screen7 from '../Screen7';
import schema7 from '../Screen7/schema';

import Screen8 from '../Screen8';
import schema8 from '../Screen8/schema';

const useScreensForManufacturedHomeowner = () => (
  [
    // { Component: Screen0, schema: schema0 },
    // { Component: Screen1, schema: schema1 },
    // { Component: Screen2, schema: schema2 },
    // { Component: Screen3, schema: schema3 },
    // { Component: Screen4, schema: schema4 },
    // { Component: Screen5, schema: schema5 },
    { Component: Screen6, schema: schema6 },
    // { Component: Screen7, schema: schema7 },
    { Component: Screen8, schema: schema8 },
  ]
);

export default useScreensForManufacturedHomeowner;
