import React from 'react';
import clsx from 'clsx';
import Markdown from 'markdown-to-jsx';
import { Container } from 'react-bootstrap';
import Header from '../../../../Header/Header';
import Helpers from '../../../../../utils/Helpers';
import useIsBlockVisible from '../../../hooks/useIsBlockVisible';

const TextWithFactsBlock = ({ className, data, ...rest }) => {
  const isVisible = useIsBlockVisible(data, ['header', 'body', 'stats']);

  if (!isVisible) return null;

  return (
    <section
      className={clsx('TextWithFactsBlock', className)}
      {...rest}
    >
      <Container>
        {data.header && (
          <Header
            className="TextWithFactsBlock__header"
            header={data.header}
          />
        )}
        {data.body && (
          <Markdown
            className="TextWithFactsBlock__body"
            options={{ forceBlock: true }}
          >
            {Helpers.preventOrphans(data.body)}
          </Markdown>
        )}
        <div className="TextWithFactsBlock__columns">
          {data.facts.map(({ id, stat, subText }) => (
            <div
              className="TextWithFactsBlock__column"
              key={id}
            >
              <h3 className="TextWithFactsBlock__column__header">
                {stat}
              </h3>
              <p className="TextWithFactsBlock__column__text">
                {subText}
              </p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default TextWithFactsBlock;
