import * as yup from 'yup';

const schema = {
  isAnotherPersonBusinessResponsible: yup.string().default(''),
  responsiblePartyFirstName: yup.string().default(''),
  responsiblePartyMiddleName: yup.string().default(''),
  responsiblePartyLastName: yup.string().default(''),
  responsiblePartyStreetAddress: yup.string().default(''),
  responsiblePartyCity: yup.string().default(''),
  responsiblePartyState: yup.string().default(''),
  responsiblePartyZip: yup.string().default(''),
  responsiblePartyInsuranceCompanyName: yup.string().default(''),
  responsiblePartyInsuranceCompanyPolicyNumber: yup.string().default(''),
};

export default schema;