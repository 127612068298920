import React, { useMemo } from 'react';
import clsx from 'clsx';
import parse from 'html-react-parser';
import { get } from 'lodash';
import Helpers from '../../../../../../../utils/Helpers';
import Markdown from 'markdown-to-jsx';

const Slide = ({ className, slide }) => {
  const {
    body,
    image,
    header,
    name,
    title,
  } = slide;

  const [formattedBody, formattedHeader] = useMemo(
    () => [body, header].map(Helpers.preventOrphans),
    [body, header],
  );

  const [alternativeText, formats] = useMemo(
    () => ['alternativeText', 'formats'].map((key) => get(image, `data.attributes[${key}]`)),
    [image],
  );

  const meta = useMemo(
    () => [name, title].filter(Boolean).join('&nbsp;&nbsp;|&nbsp;&nbsp;'),
    [name, title],
  );

  return (
    <figure className={clsx('Slide', className)}>
      {formats?.large?.url ? (
        <div className="Slide__img__platform">
          <div className="Slide__img__stage">
            <div className="Slide__img__wrapper">
              <img
                alt={alternativeText || ''}
                className="Slide__img"
                src={formats.large.url}
              />
            </div>
          </div>
        </div>
      ) : (
        <span
          aria-hidden="true"
          className="Slide__img__placeholder"
        />
      )}
      <figcaption className="Slide__caption__wrapper">
        <div className="Slide__caption">
          {formattedHeader && (
            <p
              aria-hidden="true"
              className="Slide__caption__header"
            >
              {parse(formattedHeader)}
            </p>
          )}
          {formattedBody && (
            <Markdown
              className="Slide__caption__body"
              options={{ forceBlock: true }}
            >
              {formattedBody}
            </Markdown>
          )}
          {meta && (
            <p className="Slide__caption__meta">
              {parse(meta)}
            </p>
          )}
        </div>
      </figcaption>
    </figure>
  );
};

export default Slide;
