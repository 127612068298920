import * as yup from 'yup';

const max = 1000;
const message = `Your vehicleDamageDescription may not be than ${max.toLocaleString()} characters.`;

const schema = {
  damageType: yup.string().default('').required('This field is required.'),
  isVehicleDamaged: yup.string().default('').required('This field is required.'),
  isVehicleDrivable: yup.string().default(''),
  isBusinessAddress: yup.string().default(''),
  pointOfImpact: yup.string().default(''),
  vehicleDamageDescription: yup.string().default('').max(max, message),
  unrelatedPriorDamage: yup.string().default('').max(max, message),
  vehicleLocationStreetAddress: yup.string().default(''),
  vehicleLocationCity: yup.string().default(''),
  vehicleLocationState: yup.string().default(''),
  vehicleLocationZip: yup.string().default(''),
  isBusinessAddress: yup.string().default(''),
  businessName: yup.string().default(''),
  businessPhone: yup.string().default(''),
};

export default schema;
export { max };
