import React from 'react';
import clsx from 'clsx';
import parse from 'html-react-parser';
import { Container } from 'react-bootstrap';
import BackgroundImage from '../../../BackgroundImage/BackgroundImage';
import Tiles from '../../../Tiles/Tiles';
import useHeaderAs from '../../hooks/useHeaderAs';

const HomeHeroBlock = ({ data, className, ...rest }) => {
  const headerTextColor = 'black';
  const [ref, headerAs] = useHeaderAs('h1', 'h2');

  return (
    <section className={clsx('HomeHeroBlock', className)}>
      <div
        className={clsx('HomeHeroBlock__header__wrapper', headerTextColor)}
        ref={ref}
        {...rest}
      >
        {headerAs && (() => {
          const Header = headerAs;
          return (
            <Header className="HomeHeroBlock__header">
              {parse(data.header)}
            </Header>
          );
        })()}
      </div>
      <BackgroundImage
        className="HomeHeroBlock__backgroundImage"
        image={data.image}
      />
      <div className="HomeHeroBlock__tiles__wrapper">
        <Container fluid="lg">
          <Tiles
            className="HomeHeroBlock__tiles"
            headerAs="h2"
            tiles={data.tiles}
          />
        </Container>
      </div>
    </section>
  );
};

export default HomeHeroBlock;
