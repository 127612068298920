import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Logo from './components/Logo/Logo';
import MainLinks from './components/MainLinks/MainLinks';
import TopLinks from './components/TopLinks/TopLinks';
import links from './links.json';

const MainMenus = ({ announcementBannerHeight, className, companyInfo }) => {
  const { mainLinks, topLinks } = links;
  const [areMainLinksVisible, setMainLinksVisibility] = useState(false);
  const location = useLocation();

  useEffect(() => setMainLinksVisibility(false), [location]);

  return (
    <nav className={clsx('MainMenus', className, { areMainLinksVisible })}>
      <Container fluid="lg">
        <Logo
          areMainLinksVisible={areMainLinksVisible}
          className="MainMenus__logo"
        />
      </Container>

      <TopLinks
        className="MainMenus__topLinks"
        topLinks={topLinks}
      />

      <Container fluid="lg" >
        <MainLinks
          areMainLinksVisible={areMainLinksVisible}
          announcementBannerHeight={announcementBannerHeight}
          className="MainMenus__mainLinks"
          companyInfo={companyInfo}
          mainLinks={mainLinks}
          setMainLinksVisibility={setMainLinksVisibility}
        />
      </Container>
    </nav>
  );
};

export default MainMenus;
