import React from 'react';
import clsx from 'clsx';
import Tile from './components/Tile/Tile';

const Tiles = ({ className, headerAs = 'h3', tiles, displayAsIcons }) => 
(
  <div className={clsx('Tiles', className)}>
    {tiles.map(({ body, header, id, image, linkSlug}) => (
      <Tile
        body={body}
        scrollLink={linkSlug}
        className="Tiles__tile"
        header={header}
        headerAs={headerAs}
        id={id}
        key={id}
        image={image}
        displayAsIcons={displayAsIcons}
      />
    ))}
  </div>
);

export default Tiles;
