import * as yup from 'yup';

const schema = {
  insured: yup.string().default('').nullable().required('This field is required.'),  
  notInsuredType: yup.string().default('').nullable().when('insured', {
    is: 'No',
    then: (schema) => schema.required('This field is required.'),
  }),
  notInsuredTypeOther: yup.string().default('').when('notInsuredType', {
    is: 'Other',
    then: (schema) => schema.required('This field is required.'),
  }),
};

export default schema;
