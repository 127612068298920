import Screen0 from '../Screen0';
import schema0 from '../Screen0/schema';
import Screen1 from '../Screen1';
import schema1 from '../Screen1/schema';
import Screen2 from '../Screen2';
import schema2 from '../Screen2/schema';
import Screen3 from '../Screen3';
import schema3 from '../Screen3/schema';
import Screen4 from '../Screen4';
import schema4 from '../Screen4/schema';
import Screen5 from '../Screen5';
import schema5 from '../Screen5/schema';
import Screen6 from '../Screen6';
import schema6 from '../Screen6/schema';
import Screen7 from '../Screen7';
import schema7 from '../Screen7/schema';
import Screen8 from '../Screen8';
import schema8 from '../Screen8/schema';
import Screen9 from '../Screen9';
import schema9 from '../Screen9/schema';
import Screen10 from '../Screen10';
import schema10 from '../Screen10/schema';
import Screen11 from '../Screen11';
import schema11 from '../Screen11/schema';
import Screen12 from '../Screen12';
import schema12 from '../Screen12/schema';
import Screen13 from '../Screen13';
import schema13 from '../Screen13/schema';
import Screen14 from '../Screen14';
import schema14 from '../Screen14/schema';
import Screen15 from '../Screen15';
import schema15 from '../Screen15/schema';

const useScreensForPersonalAuto = () => (
  [
    { Component: Screen0, schema: schema0 },
    { Component: Screen1, schema: schema1 },
    { Component: Screen2, schema: schema2 },
    { Component: Screen3, schema: schema3 },
    { Component: Screen4, schema: schema4 },
    { Component: Screen5, schema: schema5 },
    { Component: Screen6, schema: schema6 },
    { Component: Screen7, schema: schema7 },
    { Component: Screen8, schema: schema8 },
    { Component: Screen9, schema: schema9 },
    { Component: Screen10, schema: schema10 },
    { Component: Screen11, schema: schema11 },
    { Component: Screen12, schema: schema12 },
    { Component: Screen13, schema: schema13 },
    { Component: Screen14, schema: schema14 },
    { Component: Screen15, schema: schema15 },
  ]
);

export default useScreensForPersonalAuto;
