import React, { forwardRef } from 'react';
import clsx from 'clsx';

const RadioOption = forwardRef(({
  children,
  className,
  isOtherOption,
  name,
  onBlur,
  onChange,
  value,
  ...rest
}, ref) => (
  <label className={clsx('RadioOption__wrapper', className, { isOtherOption })}>
    <input
      className={clsx('RadioOption', 'visually-hidden')}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      ref={ref}
      type="radio"
      value={value}
      {...rest}
    />
    <span
      aria-hidden="true"
      className="RadioOption__fauxIcon"
    />
    <span
      aria-hidden="true"
      className="RadioOption__fauxOutline"
    />
    <span className="RadioOption__label">
      {children}
    </span>
  </label>
));

export default RadioOption;
