import React, { useMemo } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams
} from 'react-router-dom';
import Page from '../views/Page/Page';

const DynamicRouter = ({
  announcement,
  footer,
  // mainMenus,
  mapDatas,
  pages,
}) => {
  const orderedPages = useMemo(
    () =>
      pages
        .reduce((x, page) => {
          const isHomepage = page.attributes.slug === 'home';
          // put the homepage in the first position
          if (isHomepage) return [{ path: '/', ...page }, ...x];
          // construct the `path` property for the router
          const path = `/${page.attributes.slug}`;
          // place all other pages after the homepage
          return [...x, { path, ...page }];
        }, [])
        .reverse(),
    [pages],
  );
  const MyRedirect = () => {
    const { id } = useParams();
    console.log(id)
    return <Navigate to={`/make-a-payment#${id}`} replace />;
  };

  return (
    <Router>
      <Routes>
        {orderedPages.map((page) => (
          <Route
            key={page.attributes.slug}
            path={page.path}
            element={(
              <Page
                announcement={announcement.attributes}
                footer={footer.attributes}
                mapDatas={mapDatas}
                page={page.attributes}
              />
            )}
          />
        ))}
        <Route path="/pnapi/qr/:id" element={<MyRedirect />} />

        <Route
          path="*"
          element={<Navigate replace to="/" />}
        />
      </Routes>
    </Router>
  );
}

export default DynamicRouter;
