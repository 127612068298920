import * as yup from 'yup';

const max = 1000;
const message = `Your vehicleDamageDescription may not be than ${max.toLocaleString()} characters.`;

const schema = {
  causeOfLoss: yup.string().default('').required('This field is required.'),
  isPropertyDamaged: yup.string().default(''),
  damageToPropertyDescription: yup.string().default('').max(max, message),
  damageToContentsDescription: yup.string().default('').max(max, message),
  isInsuredUsingContractor: yup.string().default(''),
  contractorName: yup.string().default(''),
  contractorPhone: yup.string().default(''),
  contractorLocationStreetAddress: yup.string().default(''),
  contractorLocationCity: yup.string().default(''),
  contractorLocationState: yup.string().default(''),
  contractorLocationZip: yup.string().default(''),
  isInsuredPropertyLiveable: yup.string().default(''),
  tempLocationPhone: yup.string().default(''),
  tempLocationStreetAddress: yup.string().default(''),
  tempLocationCity: yup.string().default(''),
  tempLocationState: yup.string().default(''),
  tempLocationZip: yup.string().default('')

};

export default schema;
export { max };
