import React, {
  Children,
  cloneElement,
  
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  get,
  has,
  isBoolean,
  noop,
  uniqueId
} from 'lodash';
import clsx from 'clsx';
import Input from '../Input/Input';
import InputWrapper from '../InputWrapper/InputWrapper';
import RadioOption from './components/RadioOption/RadioOption';

const RadioGroup = ({
  children,
  className,
  error,
  form,
  helperText,
  id,
  isLabelHidden,
  isStacked,
  label,
  name,
  onChange = noop,
  otherOption,
  required,
  touched,
}) => {
  const optionsChildren = Children.toArray(children);

  const radioGroupId = useMemo(() => id || uniqueId('radiogroup-'), [id]);
  const radioGroupValue = form?.watch(name);

  const [hasOtherOption, otherOptionValue] = useMemo(
    () => [
      has(otherOption, 'name'),
      get(otherOption, 'value') || 'Other',
    ],
    [otherOption],
  );

  const isOtherOptionRequired = useMemo(
    () => (hasOtherOption && radioGroupValue === otherOptionValue),
    [hasOtherOption, otherOptionValue, radioGroupValue],
  );

  return (
    <InputWrapper
      className={clsx('RadioGroup', className, {
        'hasLabel': label && !isLabelHidden,
      })}
      error={error}
      helperText={helperText}
      labelHtmlFor={radioGroupId}
      isLabelHidden={isLabelHidden}
      label={label}
      role="radiogroup"
      touched={touched}
    >
      <div
        className={clsx('RadioGroup__options', { hasOtherOption, isStacked })}
        id={radioGroupId}
        role="none"
        {...(label && { 'aria-labelledby': radioGroupId })}
      >
        {Children.map(optionsChildren, (child, i) => {
          const {
            className: optionClassName,
            id: childId,
            value,
            ...props
          } = child.props;
          const option = { ...child, props };
          const optionId = childId || `${radioGroupId}-${i}`;
          const requiredMessage = isBoolean(required) ? 'Please select an option.' : required;

          return (
            <RadioOption
              aria-labelledby={id}
              className={optionClassName}
              value={value}
              {...form?.register(name, {
                onChange,
                required: required ? requiredMessage : false,
              })}
            >
              {cloneElement(option, { id: optionId })}
            </RadioOption>
          );
        })}

        {hasOtherOption && (() => {
          const otherInputId = otherOption.id || `${radioGroupId}-other-input`;
          const otherInputLabelId = `${otherInputId}-label`;
          const requiredMessage = otherOption.registeroptions?.required || 'Please enter an value.';

          return (
            <RadioOption
              aria-labelledby={otherInputLabelId}
              isOtherOption
              value={otherOptionValue}
              {...form?.register(name, {
                onChange: (e) => {
                  onChange(e);

                  if (e.currentTarget.value === otherOptionValue) {
                    form?.setFocus(otherOption.name);
                    return;
                  }

                  form?.resetField(otherOption.name);
                },
              })}
            >
              <Input
                error={form?.formState.errors[otherOption.name]}
                id={otherInputId}
                isLabelHidden
                label="Other"
                labelId={otherInputLabelId}
                onFocus={() => form?.setValue(name, otherOptionValue)}
                placeholder="Other"
                touched={form?.formState.touchedFields[otherOption.name]}
                type={otherOption.type || 'text'}
                {...form?.register(otherOption.name, {
                  ...otherOption.registeroptions,
                  required: isOtherOptionRequired ? requiredMessage : false,
                })}
              />
            </RadioOption>
          );
        })()}
      </div>
    </InputWrapper>
  );
};

export default RadioGroup;
