import React from 'react'
import { Helmet } from 'react-helmet';
function SEO({title, description, indexStatus}) {
    const siteName = process.env.REACT_APP_WEBSITE_NAME
    if (typeof title === 'undefined') {
        title=siteName;
    }
    if (typeof description === 'undefined') {
        description="Stonegate Insurance Company";
    }
    

    return (
        <Helmet defaultTitle={siteName} defaultDescription="Stonegate Insurance Company">
            <title>{ `${siteName} | ${ title }` }</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={ `${siteName} | ${ title }` } />
            {indexStatus && <meta name="robots" content='noindex' /> }
        </Helmet>
    )
}

export default SEO
