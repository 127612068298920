import * as yup from 'yup';

const schema = {
  policyNumber: yup.string().default('').required('This field is required.'),
  dateOfAccident: yup.string().default('').required('This field is required.'),
  timeOfAccident: yup.string().default('').required('This field is required.'),
  locationOfAccidentStreetAddress: yup.string().default(''),
  locationOfAccidentCity: yup.string().default('').required('This field is required.'),
  locationOfAccidentState: yup.string().default('').required('This field is required.'),
  locationOfAccidentZip: yup.string().default(''),
};

export default schema;
